export default function MiddleInfo() {
  return (
    <div className='middle-info'>
      <div className="middle-info__container">

        <div className="middle-info__content">
          <div>
            Телевизорная 1 строение 10 <br />
            г.Красноярск, 660028 <br />
            Телефон: <a className="middle-info__link middle-info__link_white" href="tel:2560540">2560540</a> <br />
            <a className="middle-info__link middle-info__link_white" href="tel:8902926-97-03">8(902)926-97-03</a> <br />
            Email: <a className="middle-info__link" href="mailto:ritualnaya24@gmail.com">ritualnaya24@gmail.com</a>
            </div>
        </div>

        <div className="middle-info__description">20 ЛЕТ ПРЕДОСТАВЛЕНИЯ УСЛУГ
        </div>
      </div>
    </div>
  )
}
