import { css, Global } from "@emotion/react";
import styled from "@emotion/styled";
import {
  blue,
  blueHover,
  lightBlue,
  lightBlueHover,
  black,
  blackHover,
} from "./colors";

export const globalStyles = (
  <Global
    styles={css`
      @font-face {
        font-family: "VerdanaDev";
        src: url("../../fonts/Verdana/Verdana.woff2") format("woff2"),
          url("../../fonts/Verdana/Verdana.woff") format("woff");
        font-weight: 400;
        font-style: normal;
        font-display: swap;
      }

      @font-face {
        font-family: "VerdanaDev";
        src: url("../../fonts/Verdana/Verdana-Bold.woff2") format("woff2"),
          url("../../fonts/Verdana/Verdana-Bold.woff") format("woff");
        font-weight: 700;
        font-style: normal;
        font-display: swap;
      }

      @font-face {
        font-family: "GeorgiaDev";
        src: url("../../fonts/Georgia/Georgia.woff2") format("woff2"),
          url("../../fonts/Georgia/Georgia.woff") format("woff"),
          url("../../fonts/Georgia/Georgia.ttf") format("ttf");
        font-weight: 400;
        font-style: normal;
        font-display: swap;
      }

      @font-face {
        font-family: "GeorgiaDev";
        src: url("../../fonts/Georgia/Georgia-Bold.woff2") format("woff2"),
          url("../../fonts/Georgia/Georgia-Bold.woff") format("woff"),
          url("../../fonts/Georgia/Georgia-Bold.ttf") format("ttf");
        font-weight: 700;
        font-style: normal;
        font-display: swap;
      }
      
      body {
        position: relative;
        font-family: "VerdanaDev", "GeorgiaDev", sans-serif;
        height: 100%;
        line-height: 1.35;
        min-width: 320px;
        font-size: 14px;
        width: 100%;
        font-weight: 400;
        margin: 0;
        padding: 0;

        background-image: url("../../bg_ornament.webp"); 
        background-repeat: repeat-y;
        background-size: 100%;
          
        @media screen and (max-width: 576px) {
          font-size: 14px;
        }

        &::before {
          content: "";
          position: absolute;
          top: 45px;
          left: 0;
          right: 0;
          height: 485px;
          background-color: #000;
          @media screen and (max-width: 768px) {
            top: 35px;
            height: 360px;
          }
        }

        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 300px;
          background-color: #000;
          @media screen and (max-width: 768px) {
            height: 855px;
          }
        }
      }

      #__next {
        position: relative;
        display: flex;
        flex-direction: column;
        min-height: 100vh;
      }

      p,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      a,
      input,
      input::placeholder,
      textarea,
      textarea::placeholder,
      button,
      span,
      div {
        line-height: inherit;
        font-family: inherit;
        font-size: inherit;
        margin: 0;
      }
      img {
        display: block;
        max-width: 100%;
      }
      input,
      textarea {
        padding-top: 0;
        padding-bottom: 0;
      }
      * {
        box-sizing: border-box;
      }
      a, button {
        cursor: pointer;
        padding: 0;
        color: inherit;
      }
      button {
        border: 0;
        outline: 0;
        background-color: transparent;
      }
      a {
        &:hover, &:focus {
          text-decoration: underline;
        }
      }
    `}
  />
);

export const Title = styled.p`
  font-size: 28px;
  font-family: Verdana, serif;
  color: #63552d;
  margin: 0;
  margin: ${({ margin }) => margin || 0};
`;


export const Text = styled.p`
  margin: ${({ margin }) => margin || 0};
  font-style: ${({ fontStyle }) => fontStyle || 'normal'};
  font-weight: ${({ fontWeight }) => fontWeight || 400};
  color: ${({ color }) => color || '#000000'};
  font-family: ${({ fontFamily }) => fontFamily || 'VerdanaDev'};
  font-size: ${({ fontSize }) => fontSize || 'inherit'};
  text-indent: ${({ textIndent }) => textIndent || 'unset'};
`;

export const TextBold = styled(Text)`
  font-weight: 700;
`;