import Link from "next/link";
import React from "react";

export default function Footer() {
  return (
    <footer className="footer">
      <div className="container footer__container">
        <div className="footer__list">
          <p className="footer__list-title">О нас</p>
          <Link href="/about/possibilities">
            <a className="footer__list-item">Наши возможности</a>
          </Link>
          <Link href="/about/history">
            <a className="footer__list-item">Наша история</a>
          </Link>
          <Link href="/about/carpark">
            <a className="footer__list-item">Что делает нас уникальными</a>
          </Link>
          <Link href="/about/reviews">
            <a className="footer__list-item">Отзывы</a>
          </Link>
          <Link href="/about/contacts/">
            <a className="footer__list-item">Свяжитесь с нами</a>
          </Link>
        </div>

        <div className="footer__list">
          <p className="footer__list-title">Наши услуги</p>
          <Link href="/services/planing">
            <a className="footer__list-item">Похороны</a>
          </Link>
          <Link href="/services/krem">
            <a className="footer__list-item">Кремация</a>
          </Link>
          <Link href="/services/funeralhalls">
            <a className="footer__list-item">Прощание</a>
          </Link>
          <Link href="/services/funeral">
            <a className="footer__list-item">Поминки</a>
          </Link>
          <Link href="/services/monument">
            <a className="footer__list-item">Памятники</a>
          </Link>
        </div>

        <div className="footer__list">
          <p style={{opacity: 0}} className="footer__list-title">Дополнительные разделы</p>
          <Link href="/services/venki">
            <a className="footer__list-item">Венки/цветы</a>
          </Link>
          <Link href="/services/transp">
            <a className="footer__list-item">Транспортные услуги</a>
          </Link>
          <Link href="/services/lifecont">
            <a className="footer__list-item">Прижизненный договор</a>
          </Link>
          <Link href="/services/dop">
            <a className="footer__list-item">Дополнительные услуги</a>
          </Link>
        </div>

        <div className="footer__list">
          <p style={{opacity: 0}} className="footer__list-title">Контакты</p>
          <p className="footer__list-item footer__list-item_text">
            Телевизорная 1 строение 10 <br />
            г.Красноярск, 660028
          </p>
          <Link href="/">
            <a className="footer__list-item">Телефон: 2560540</a>
          </Link>
          <Link href="/">
            <a className="footer__list-item">8 (902) 926-97-03</a>
          </Link>
          <Link href="/">
            <a className="footer__list-item">Email: <span>ritualnaya24@gmail.com</span></a>
          </Link>
        </div>
      </div>
    </footer >
  );
}
