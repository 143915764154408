import styled from '@emotion/styled';
import { motion } from 'framer-motion'

export const Nav = styled.div`
  background-image: url("../../bg_ornament.webp"); 
  background-repeat: repeat-y;
  background-size: 100%;
  @media screen and (max-width: 768px) {
    background-image: none;
  }
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 45px;
  background: rgba(0, 0, 0, 0.23);
  @media screen and (max-width: 768px) {
    height: 100px;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
    align-items: center;
    font-size: 14px;
  }
`;

export const Button = styled.button`
  color: #fff;
  text-shadow: 1px 1px 0px rgb(0 0 0);
  &:hover {
    text-decoration: underline;
  }
  @media screen and (max-width: 768px) {
    margin: 45px 0 5px 0;
  }
`;

export const StyledLink = styled.a`
  text-decoration: none;
  color: #fff;
  text-shadow: 1px 1px 0px rgb(0 0 0);
`;

export const ReconstructionDropdown = styled(motion.div)`
  position: absolute;
  left: 15px;
  right: 15px;

  text-align: left;
  color: #fff;

  box-sizing: content-box;

  transition: visibility .3s ease;
  z-index: 10;
  @media screen and (max-width: 768px) {
    left: 15px;
    right: 15px;
    width: calc(100% - 30px);
    font-size: 14px;
  }
`;

export const ReconstructionDropdownContent = styled.div`
  position: relative;

  width: 100%;
  height: 100%;
  overflow: hidden;
  
  border: 1px solid rgb(231, 218, 168);
  border-top-width: 6px;
  border-radius: 0 0 8px 8px;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 300px;
    
    background-image: url('../../big-drop_bg.webp');
    background-repeat: no-repeat;
    background-size: cover;
    pointer-events: none;
  }

`;

export const ReconstructionTitle = styled.p`
  position: absolute;

  line-height: 23px;
  margin: 0 auto;
  border: 1px solid #d4caa2;
  background-image: url('../../bg_nav.webp');
  background-size: cover;
  background-position: center;
  box-shadow: 0px 3px 5px 0px rgb(0 0 0);
  text-shadow: 1px 1px 0px rgb(0 0 0);
  width: 217px;
  border-radius: 0 0 5px 5px;
  text-align: center;
  pointer-events: all;
  cursor: pointer;
  position: relative;
  color: #fff;
  z-index: 10;
  span {
    position: relative;
    display: block;
    &::before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      pointer-events: none;
      background-image: -moz-linear-gradient( 90deg, rgba(0, 0, 0, 0.2) 0%, rgba(220, 213, 198, 0.2) 60%, rgba(220, 213, 198, 0.2) 77%, rgba(50, 50, 50, 0.1) 100% );
      background-image: -webkit-linear-gradient( 90deg, rgba(0, 0, 0, 0.2) 0%, rgba(220, 213, 198, 0.2) 60%, rgba(220, 213, 198, 0.2) 77%, rgba(50, 50, 50, 0.1) 100% );
      background-image: -ms-linear-gradient( 90deg, rgba(0, 0, 0, 0.2) 0%, rgba(220, 213, 198, 0.2) 60%, rgba(220, 213, 198, 0.2) 77%, rgba(50, 50, 50, 0.1) 100% );
      z-index: -1;
    }
  }
`

export const DropDown = styled(motion.div)`
  position: absolute;
  top: 100%;
  left: 15px;

  padding: 15px;

  text-align: left;
  color: #fff;

  overflow: hidden;
  box-sizing: content-box;

  border: 1px solid rgb(231, 218, 168);
  border-top-width: 6px;
  border-radius: 0 0 8px 8px;
  box-shadow: 1.089px 1.677px 10px 0px rgb(0 0 0), inset 0px 0px 10px 0px rgb(0 0 0);

  transition: visibility .3s ease;
  z-index: 10;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 230px;
    pointer-events: none;

    box-shadow: 0px 3px 5px 0px rgb(0 0 0), inset 0px 1px 10px 0px rgb(0 0 0);
    background-image: url('../../dropdown-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    @media screen and (max-width: 768px) {
      width: 100%;
      height: 250px;
    }
    @media screen and (max-width: 480px) {
      height: 290px;
    }
  }

  @media screen and (max-width: 768px) {
      left: 15px;
      right: 15px;
      font-size: 14px;
    }
  `;

export const DropDownWrapper = styled.div`
  position: relative;
  pointer-events: none;
  a {
    pointer-events: all;
  }
`

export const DropDownTopText = styled.div`
  margin-bottom: 15px;
`;

export const DropPhones = styled.div`
  display: flex; 
  align-items: center;
  margin-bottom: 20px;
  font-size: 20px;
  @media screen and (max-width: 576px) {
    display: block;
    font-size: 16px;
    text-align: center;
    margin-bottom: 15px;
  }
  a {
    color: #d4caa2;
    text-decoration: underline;
    text-shadow: 1px 1px 0px rgb(0 0 0);
    &:hover {
      text-decoration: none;
    }
  }
  span {
    display: block;
    margin: 0 10px;
  }
`;

export const DropDownBottomText = styled.div`
  p {
    margin-bottom: 10px;
  }
  a {
    color: #d4caa2;
    text-decoration: underline;
    text-shadow: 1px 1px 0px rgb(0 0 0);
    &:hover {
      text-decoration: none;
    }
  }
`;