import React, { useRef, useState } from 'react'
import Link from 'next/link'
import { FiMenu } from 'react-icons/fi';
import { VscChromeClose } from 'react-icons/vsc';
import { AiFillHome } from 'react-icons/ai';
import { useRouter } from 'next/router';

export default function MiddleNav() {
  const router = useRouter();
  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useRef()
  const openMenuHandler = () => setIsOpen(prev => !prev)

  return (
    <div className="middle-nav">
      <div className="middle-nav__container">
        <div ref={dropdownRef} className={isOpen ? "middle-nav__items active" : "middle-nav__items"}>
          <Link passHref href="/"><a onClick={openMenuHandler} className={router.asPath === "/" ? "middle-nav__item middle-nav__item_first active" : "middle-nav__item middle-nav__item_first"}><img src="/home-icon.png" alt="Главная"/><span>Главная</span></a></Link>
          <Link passHref href="/about"><a onClick={openMenuHandler} className={router.asPath.includes('/about') ? "middle-nav__item active" : "middle-nav__item"}> О Нас </a></Link>
          <Link passHref href="/services"><a onClick={openMenuHandler} className={router.asPath.includes('/services') ? "middle-nav__item active" : "middle-nav__item"}>Наши услуги </a></Link>
          <Link passHref href="/services/planing"><a onClick={openMenuHandler} className="middle-nav__item">Похороны </a></Link>
          <Link passHref href="/services/funeralhalls"><a onClick={openMenuHandler} className="middle-nav__item">Прощание </a></Link>
          <Link passHref href="/services/funeral"><a onClick={openMenuHandler} className="middle-nav__item">Поминки </a></Link>
          <Link passHref href="/services/monument"><a onClick={openMenuHandler} className="middle-nav__item">Памятники </a></Link>
          <Link passHref href="/services/venki"><a onClick={openMenuHandler} className="middle-nav__item">Венки/цветы </a></Link>
        </div>
        <button onClick={openMenuHandler} className="middle-nav__menu-btn">{isOpen ? <VscChromeClose /> : <FiMenu />}</button>
      </div>
    </div>
  )
}
