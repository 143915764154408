import Footer from "@/sections/Footer";
import MiddleInfo from "@/sections/MiddleInfo";
import MiddleNav from "@/sections/MiddleNav";
import TopNav from "@/sections/TopNav";

export default function DefaultLayout({ children }) {
  return (
    <>
      <TopNav />
      <div className="default-layout">
        <div className="container default-layout__container">
          <MiddleInfo />
          <MiddleNav />
          {children}
        </div>
      </div>
      <Footer />
    </>
  );
}
