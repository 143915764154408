import Script from 'next/script'
import dynamic from 'next/dynamic';
const SimpleReactLightbox = dynamic(import('simple-react-lightbox'), { ssr: true })

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/effect-fade';
import '@/styles/base.scss';

import { globalStyles } from '@/styled/base'
import DefaultLayout from '@/components/layouts/DefaultLayout';
const isProduction = process.env.NODE_ENV === 'production' ? true : false;

function App({ Component, pageProps, router }) {
  return (
    <>
      {isProduction && (
        <>
          <Script id="gtag" strategy='lazyOnload' src="https://www.googletagmanager.com/gtag/js?id=UA-213638857-1" />
          <Script id="gscript" dangerouslySetInnerHTML={{
            __html: `window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
          
            gtag('config', 'UA-213638857-1');`}}>
          </Script>
          <Script id="yandex" strategy='lazyOnload' src="https://api-maps.yandex.ru/2.1/?apikey=63d72663-f94d-4c5e-a985-8338eb1eefb2&lang=ru_RU" />
          <Script id="webmaster" dangerouslySetInnerHTML={{
            __html: `(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
            m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
            (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
         
            ym(89533978, "init", {
                 clickmap:true,
                 trackLinks:true,
                 accurateTrackBounce:true
            });`}}>
          </Script>
          <Script id="webmasterWartch" strategy='lazyOnload'><div><img src="https://mc.yandex.ru/watch/89533978" style="position:absolute; left:-9999px;" alt="" /></div></Script>
        </>
      )}
      <SimpleReactLightbox>
        <DefaultLayout>
          {globalStyles}
          <Component {...pageProps} />
        </DefaultLayout>
      </SimpleReactLightbox>
    </>
  )
}

export default App;

/**
 * <script type="text/javascript" >
   
</script>
<noscript><div><img src="https://mc.yandex.ru/watch/89533978" style="position:absolute; left:-9999px;" alt="" /></div></noscript>
 */