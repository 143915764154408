import Link from 'next/link'
import { useEffect, useRef, useState } from 'react';
import CustomLink from '@/components/shared/CustomLink';
import {
  Button,
  Container,
  DropDown,
  DropDownBottomText,
  DropDownTopText,
  DropDownWrapper,
  DropPhones,
  Nav,
  ReconstructionDropdown,
  ReconstructionDropdownContent,
  ReconstructionTitle,
  StyledLink
} from './styled';
import useStore from 'store/store';

export default function TopNav() {

  const activeLeftMenu = useStore((state) => state.activeLeftMenu);
  const activeLeftMenuSet = useStore((state) => state.activeLeftMenuSet);
  const activeLeftMenuToggle = useStore((state) => state.activeLeftMenuToggle);

  const dropdownVariants = {
    closed: {
      opacity: 0,
      height: 0,
      pointerEvents: 'none'
    },
    animate: active => ({
      opacity: active ? 1 : 0,
      height: active ? 'auto' : 0,
      pointerEvents: active ? 'auto' : 'none'
    }),
    exit: active => ({
      height: 0,
      pointerEvents: active ? 'auto' : 'none',
    })
  }
  
  const dropdownRef = useRef()
  const btnDropdownRef = useRef()

  const reconstructionVariants = {
    closed: {
      top: -7,
      height: 0,
      pointerEvents: 'none'
    },
    animate: active => ({
      top: active ? 0 : -7,
      height: active ? 288 : 0,
      pointerEvents: active ? 'auto' : 'none'
    }),
    exit: active => ({
      top: -7,
      pointerEvents: active ? 'auto' : 'none',
      height: 0
    })
  }
  const [isOpenReconstructions, isOpenReconstructionsSet] = useState(false)
  const reconstructionsRef = useRef()
  const btnReconstructionsRef = useRef()
  const openReconstructionsHandler = () => isOpenReconstructionsSet(prev => !prev)

  useEffect(() => {
    const condition = e => (!dropdownRef.current.contains(e.target) && activeLeftMenu) || (!reconstructionsRef.current.contains(e.target) && isOpenReconstructions);

    const dropdownEevent = e => {
      if (condition(e)) {
        activeLeftMenuSet(false)
        isOpenReconstructionsSet(false)
      }
    }

    document.addEventListener('click', dropdownEevent)
    return () => {
      document.removeEventListener('click', dropdownEevent)
    }
  })

  return (
    <Nav>
      <Container className="container">
        <Button ref={btnDropdownRef} onClick={activeLeftMenuToggle}>Круглосуточная консультация</Button>

        <DropDown
          ref={dropdownRef}
          variants={dropdownVariants}
          custom={activeLeftMenu}
          initial={dropdownVariants.closed}
          animate={dropdownVariants.animate}
          exit={dropdownVariants.exit}
          transition={{ type: "spring", duration: .7 }}>
          <DropDownWrapper>
            <DropDownTopText>
              <p>Мы здесь что бы помочь вам, если вам <br />
                необходимы наши услуги звоните 24 часа в сутки</p>
            </DropDownTopText>
            <DropPhones>
              <a href="tel:2560540">2560-540</a>
              <span>или </span>
              <a href="tel:8902926-97-03">8(902)926-97-03</a>
            </DropPhones>
            <DropDownBottomText>
              <p>Так же мы подготовили информацию, <br />
                которая может быть вам полезна:
              </p>
              <CustomLink href="/services/funeral"><a>Что нужно знать</a></CustomLink>
            </DropDownBottomText>
          </DropDownWrapper>
        </DropDown>

        <ReconstructionDropdown
          ref={reconstructionsRef}
          variants={reconstructionVariants}
          custom={isOpenReconstructions}
          initial={reconstructionVariants.closed}
          animate={reconstructionVariants.animate}
          exit={reconstructionVariants.exit}
          transition={{ type: "spring", duration: .7 }}>
          <ReconstructionDropdownContent></ReconstructionDropdownContent>
          <ReconstructionTitle onClick={openReconstructionsHandler} ref={btnReconstructionsRef}><span>Сайт на реконструкции</span></ReconstructionTitle>
        </ReconstructionDropdown>

        <Link passHref href="/about/contacts"><StyledLink>Связаться с нами / Мы на карте</StyledLink></Link>
      </Container>
    </Nav>
  )
}

